import './styles.scss'
import '@Components/Pages/Inspection/styles.scss'

import { Image } from '@Components/Pages/Inspection'
import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import React from 'react'

const Inspection = () =>
  <div className='menu-page'>
    <div className='menu-page-inspection'>
      <MenuHeader title='Inspection' className='menu-page-inspection-header' />
      <Image />
      <div className='menu-page-inspection-text'>
        <p>
          Would you like a little more detail plus extensive photos of the vehicle you are
            interested in purchasing? Please contact us at <a href='tel:8005392277'>(800) 539-2277</a> or
            via email: <a href='mailto:carvantedge@fleetstreetusa.com'>carvantedge@fleetstreetusa.com</a> to
            order a vehicle inspection for $125.00.
          </p>
        <p>
          This is a cosmetic inspection and can only be performed after the vehicle becomes
          available to us. This is not a mechanical inspection. If you are interested in this service,
          please reserve the vehicle of interest and we will contact you as soon as the vehicle becomes
          available to schedule the inspection.
          </p>
        <p>
          Please feel free to contact CarVantedge at <a href='tel:8005392277'>(800) 539-2277</a> with
            any questions, comments, and/or concerns regarding this service.
          </p>
      </div>
    </div>
  </div>

export default Inspection

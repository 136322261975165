import './styles.scss'

import React from 'react'

const MenuHeader = ({ title, className }) =>
  <div className={className ? className : "menu-header"}>
    {title}
  </div>

export default MenuHeader
